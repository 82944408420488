import React, { useRef, useState } from "react";
// import "antd/dist/antd.css";
import axios from "axios";
import { BeatLoader } from 'react-spinners';
import Modal from "../components/modal";
import SubTable from "./Table";
import StudentTable from "./phoneTable";
import InterActiveTable from "./interactiveTable";
import { down, up } from "./svg";
import { download, uploadFile } from "../assets/svgIcons";
import toast from "react-hot-toast";

const SecurityInquiry = () => {
  const [dataSend, setDataSend] = useState({ search_by: "id" });
  const [data, setData] = useState(null);
  const [phoneData, setPhoneData] = useState({});
  const [phone, setPhone] = useState(null);
  const [phoneInquire, setPhoneInquire] = useState(null);
  const [loading, setLoading] = useState(false);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [expand, setExpand] = useState(false);
  const ref = useRef();
  // setUploadLoading
  const [uploadQuestion, setUploadQuestion] = useState(false);
  // setExpand
  const inputs = [
    { name: "search_by", id: "id", value: "id" },
    { name: "search_by", id: "email", value: "email" },
    // { name: "search_by", id: "phone", value: "phone" },
  ];

  const handleSearchSubmit = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        "https://elmatary.com/El_Matary_Platform/platform/support/elemam/check_user.php",
        dataSend
      );
      console.log(response);
      setData(response?.data?.message);
      setLoading(false);
    } catch (error) {
      console.error("Error:", error);

      setLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setDataSend((prev) => {
      return { ...prev, [name]: value };
    });
  };

  const handleChangePhone = (e) => {
    setPhone(e.target.value);
  };

  const handlePhoneSearchSubmit = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        "https://elmatary.com/El_Matary_Platform/platform/support/elemam/chec_user_by_phone.php",
        { phone: phone }
      );
      console.log(response?.data);
      setPhoneData(response?.data?.message);
      setLoading(false);
    } catch (error) {
      console.error("Error:", error);

      setLoading(false);
    }
  };

  const upload = async () => {
    setUploadLoading(true);
    const formData = new FormData();
    formData.append("file_attachment", uploadQuestion);
    await axios
      .post(
        "https://elmatary.com/El_Matary_Platform/platform/support/elemam/upload_excel_question_file.php",
        formData
      )
      .then((res) => {
        toast.success(res.data);
        window.open(res.data, "_blanck");
        ref?.current?.reset();
        setUploadQuestion(false);
      })
      .finally(() => {
        setUploadLoading(false);
      });
  };

  return (
    <div className="securityInquiry">
      <div className="header">
        <h1>أداة الاستعلام الأمني</h1>
      </div>
      <div className="inputs_container rowDiv">
        <button
          className="search_button"
          style={{ width: "fit-content" }}
          onClick={() => setPhoneInquire("s")}
        >
          Phone Inquire
        </button>
        <div className="upload">
          <label
            htmlFor=""
            onClick={() =>
              window.open(
                "https://elmatary.com/El_Matary_Platform/platform/support/elemam/temp_check_ques.xlsx",
                "_blanck"
              )
            }
          >
            {download}
            <span>Download Excel Temp</span>
          </label>
        </div>
        <div className="upload">
          <label htmlFor="uploadExcel">
            {uploadFile}
            <span>Upload Questions</span>
          </label>
          <form action="" ref={ref}>
            <input
              type="file"
              onChange={(e) => setUploadQuestion(e.target.files[0])}
              name="uploadExcel"
              id="uploadExcel"
            />
          </form>
        </div>
        {uploadQuestion ? (
          <div className="fileData">
            <span
              style={{
                fontSize: "20px",
                marginRight: "6px",
                fontWeight: "900",
              }}
            >
              {uploadQuestion?.name}
            </span>
            {uploadLoading ? (
              <BeatLoader />
            ) : (
              <button
                className="search_button"
                style={{ width: "fit-content" }}
                onClick={() => upload()}
              >
                Upload File
              </button>
            )}
          </div>
        ) : null}
      </div>
      <div className="inputs_container">
        <div className="s_container">
          <h4>Search By</h4>
          <div className="radios_container">
            {inputs?.map((item) => {
              return (
                <span
                  className={
                    dataSend?.search_by == item?.value
                      ? "radio_container active"
                      : "radio_container"
                  }
                  onClick={() =>
                    handleChange({
                      target: { value: item?.value, name: item?.name },
                    })
                  }
                >
                  <input
                    type="radio"
                    name={item?.name}
                    id={item?.id}
                    value={item?.value}
                    onChange={handleChange}
                    checked={dataSend?.search_by === item?.value && true}
                  />
                  <label htmlFor={item?.id}>{item?.value}</label>
                </span>
              );
            })}
          </div>
        </div>
        <div className="rowDiv">
          <input
            className="searchInput"
            type="search"
            name="value"
            onChange={handleChange}
            placeholder="Search..."
          />
          {loading ? (
            <BeatLoader color="#ef466a" />
          ) : (
            <button className="search_button" onClick={handleSearchSubmit}>
              Search
            </button>
          )}
        </div>
      </div>
      <hr />
      {data?.student_id ? (
        <div className="f-width">
          <div className="profileDiv">
            {" "}
            <img src={data?.student_avater_url} />
            <h4 className="title" style={{ textAlign: "center", margin: "0" }}>
              #{data?.student_id}
            </h4>
            <div className="rowDiv">
              <span>
                {data?.student_name + " " + data?.student_nickname} -{" "}
              </span>
              <span>{data?.phone}</span>
            </div>
            <div className="rowDiv">
              <span>{data?.student_email}</span>
            </div>
            <span>{data?.university_title} - </span>
            <span>{data?.grade_title}</span>
            <div className="rowDiv">
              <span>Join Date : </span>
              <span>{data?.join_day} </span>
              <span>{data?.join_time}</span>
            </div>
          </div>
          <hr />
          <div className="expandable" onClick={() => setExpand("sub")}>
            {" "}
            <h4 className="title">Student Subscription</h4>
            {(expand != "sub" && <span>{down}</span>) || <span>{up}</span>}
          </div>
          {expand == "sub" && (
            <SubTable courses={data?.student_subscriptions} />
          )}
          <hr />
          <div className="expandable" onClick={() => setExpand("active")}>
            <h4 className="title">Student Interactive</h4>
            {(expand != "active" && <span>{down}</span>) || <span>{up}</span>}
          </div>
          {expand == "active" && <InterActiveTable studentData={data} />}
          <hr />
          <div className="expandable" onClick={() => setExpand("accs")}>
            <h4 className="title">Accounts Openned In The Same Device</h4>
            {(expand != "accs" && <span>{down}</span>) || <span>{up}</span>}
          </div>
          {expand == "accs" && (
            <div className="gridView">
              {data?.student_inSameDeviceArr?.map((item) => {
                return (
                  <div className="profileDiv">
                    <img src={item?.student_avater_url} />
                    <h4
                      className="title"
                      style={{ textAlign: "center", margin: "0" }}
                    >
                      #{item?.student_id}
                    </h4>
                    <div className="rowDiv">
                      <span>
                        {item?.student_name + " " + item?.student_nickname} -{" "}
                      </span>
                      <span>{item?.phone}</span>
                    </div>
                    <div className="rowDiv">
                      <span>{item?.student_email}</span>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
          <hr />
          <div className="expandable" onClick={() => setExpand("interActive")}>
            <h4 className="title">"Interactive Section" - Active Days</h4>
            {(expand != "interActive" && <span>{down}</span>) || (
              <span>{up}</span>
            )}
          </div>
          {/* ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'][new Date().getDay()] */}
          {expand == "interActive" && (
            <div className="flexDiv">
              {data?.student_interactiveActiveDays?.map((item) => {
                return (
                  <div className="day">
                    {
                      ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'][
                        new Date(item).getDay()
                      ]
                    }{" "}
                    - {item}
                  </div>
                );
              })}
            </div>
          )}
          <hr />
          <div className="expandable" onClick={() => setExpand("videos")}>
            <h4 className="title" style={{ marginTop: "15px" }}>
              "Videos Section" - Interactive Days{" "}
            </h4>
            {(expand != "videos" && <span>{down}</span>) || <span>{up}</span>}
          </div>
          {/* ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'][new Date().getDay()] */}
          {expand == "videos" && (
            <div className="flexDiv">
              {data?.student_videosActiveDays?.map((item) => {
                return (
                  <div className="day">
                    {
                      ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'][
                        new Date(item).getDay()
                      ]
                    }{" "}
                    - {item}
                  </div>
                );
              })}
            </div>
          )}
        </div>
      ) : (
        data
      )}
      <Modal
        visible={phoneInquire}
        onClose={setPhoneInquire}
        title={"Inquire By Phone"}
      >
        <div className="inputs_container">
          <div className="rowDiv">
            <input
              className="searchInput"
              type="search"
              name="value"
              onChange={handleChangePhone}
              placeholder="Search..."
            />
            {loading ? (
              <BeatLoader color="#ef466a" />
            ) : (
              <button
                className="search_button"
                onClick={handlePhoneSearchSubmit}
              >
                Search
              </button>
            )}
          </div>
          {(phoneData && phoneData[0]?.student_id && (
            <StudentTable courses={phoneData} />
          )) ||
            "No Data"}
        </div>
        <hr />
      </Modal>
    </div>
  );
};

export default SecurityInquiry;
