import React from 'react';
import SecurityInquiry from './pages/SecurityInquiry';
import "./sass/style.css";
import "./style.css";
import { Toaster } from 'react-hot-toast';
function App() {
  return (
    <div className="App">
      <SecurityInquiry />
      <Toaster />
    </div>
  );
}

export default App;
