import React, { useEffect, useState } from 'react';
import "./style.css";
const SubTable = ({ courses }) => {
  const [currentDate, setCurrentDate] = useState();
  useEffect(() => {
    const current = new Date();
    setCurrentDate(current);
  }, [courses]);
  const getDate = (date) => {
    const dateNow = new Date(date);
    return dateNow;
  };
  return (
    <div className="table">
      <table>
        <thead>
          <tr>
            <th>Course Name</th>
            <th>Subscription Start Date</th>
            <th>Subscription Start Time</th>
            <th>Subscription End Date</th>
            <th>Cancel Reason</th>
          </tr>
        </thead>
        <tbody>
          {courses.map((course, index) => (
            <tr>
              <td>{course.course_name}</td>
              <td>{course.day}</td>
              <td>{course.time}</td>
              <td
              // style={{
              //   color:
              //     currentDate >= getDate(course?.subscription_end_date)
              //       ? "red"
              //       : "auto",
              // }}
              >
                {course.subscription_end_date}
              </td>
              <td>{course.cancel_reason ? course.cancel_reason : "N/A"}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default SubTable;
