import React from 'react';
import "./style.css";
const StudentTable = ({ courses }) => {
  return (
    <div className="table">
      <table>
        <thead>
          <tr>
            <th>##</th>
            <th>student_id</th>
            <th>student_email</th>
            <th>student_name</th>
            <th>university_title</th>
            <th>grade_title</th>
          </tr>
        </thead>
        <tbody>
          {courses.map((course, index) => (
            <tr key={index}>
              <td>
                <div className="copyDiv">
                  <span>{index + 1}</span>
                </div>
              </td>
              <td>
                <div className="copyDiv">
                  <span>{course?.student_id}</span>
                </div>
              </td>
              <td>
                {" "}
                <div className="copyDiv">
                  <span>{course?.student_email}</span>
                </div>
              </td>
              <td>
                <div className="copyDiv">
                  <span>
                    {course?.student_name + " " + course?.student_nickname}
                  </span>
                </div>
              </td>
              <td>
                {" "}
                <div className="copyDiv">
                  <span>{course?.university_title}</span>
                </div>
              </td>
              <td>
                {" "}
                <div className="copyDiv">
                  <span>{course?.grade_title}</span>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default StudentTable;
